import { CaseStatus } from '@bvi/api-interfaces/entity/case';
import {
  AbuseDetailsLevel1VariantKeys,
  AbuseDetailsLevel2VariantKeys,
  AbuseDetailsLevel3VariantKeys,
  AbuseDetailsLevel4VariantKeys,
  AbuseDetailsLevel5VariantKeys,
  LegalDetailsPreparatorVariantsKey,
  VictimInfoGenderVariantKeys,
  VictimInfoAgeCategoryVariantKeys,
  PerpetratorInfoJobVariantKeys,
  PerpetratorInfoReligionOrder,
  PerpetratorInfoOrganizationVariantKeys,
  PerpetratorInfoSubsidiaryVariantKeys,
  LegalDetailsLiabilityDefenseVariantsKey,
  LegalDetailsExceptionsVariantsKey,
  AbuseDetailsKeys,
} from '@bvi/api-interfaces/entity/case-property';
import {
  AdditionalFilterParameter,
  CaseStatisticsLevel,
} from '@bvi/api-interfaces/request/case';

import { CasesDetailsTab } from '../widgets/cases-details/types';

export default {
  title: 'Burnett Valuation Index',
  auth: {
    forgotPassword: 'Forgot password?',
    registerNow: 'Register now',
    signInNow: 'Log In',
    callToSignUp: 'Don`t have an account?',
    callToSignIn: 'Already have an account?',
    signIn: {
      title: 'Log In',
      form: {
        email: {
          label: 'Email',
          placeholder: 'Enter your email',
        },
        password: {
          label: 'Password',
          placeholder: 'Enter your password',
        },
        submit: 'Log In',
      },
    },
    signUp: {
      title: 'Create an Account',

      form: {
        email: {
          label: 'Email',
          placeholder: 'Enter your email',
          emailTaken: 'Email already taken',
        },
        password: {
          label: 'Password',
          placeholder: 'Enter your password',
        },
        confirmPassword: {
          label: 'Confirm Password',
          placeholder: 'Enter your password again',
        },
        firstName: {
          label: 'First Name',
          placeholder: 'Enter your first name',
        },
        lastName: {
          label: 'Last Name',
          placeholder: 'Enter your last name',
        },
        phone: {
          label: 'Phone Number',
          placeholder: 'Enter your phone number',
        },
        companyName: {
          label: 'Company Name',
          placeholder: 'Enter your company name',
        },
        companySize: {
          label: 'Company Size',
          placeholder: 'Enter your company size',
        },
        role: {
          label: 'Your Role',
          placeholder: 'Enter your company role',
        },
        nextStep: 'Continue',
        goToFirstStep: 'Go to first step to fix email',
        submit: 'Create Account',
      },
      success: {
        title: 'Welcome!',
        text1:
          'A member of the Burnett Valuation Team will reach out to you to complete payment and activate your account.',
        text2:
          'You should receive an email within one business day. If not, please reach out to',
        email: 'support@burnettindex.com',
        button: 'Return to Login',
      },
    },
    resetPassword: {
      title: 'Reset Password',
      subtitle:
        'Enter your email and we will send you a link to reset your password.',
      backButton: 'Back to Log In',
      form: {
        email: {
          label: 'Email',
          placeholder: 'Enter your email',
        },
        submit: 'Send a Link',
      },
      success: {
        title: 'Check your email',
        text: 'We have sent you a link to create a new password.',
      },
    },
    restorePassword: {
      title: 'Restore password',
      subtitle: 'Please enter your new password',
      form: {
        password: {
          label: 'New Password',
          placeholder: 'Enter new password',
        },
        confirmPassword: {
          label: 'Confirm New Password',
          placeholder: 'Enter new password again',
        },
        submit: 'Save New Password',
      },
      success: {
        title: 'New Password Saved',
        button: 'Back to Log In',
      },
    },
  },
  header: {
    index: 'About the Index',
    search: 'Search',
    savedSearches: 'Saved Searches',
    case: 'Claim / Case',
    submitCase: 'Submit Claim / Case to Burnett Index',
    logout: 'LogOut',
    profile: 'Profile',
  },
  index: {
    title: 'Search',
    description:
      'To begin a search for the valuation of a sexual abuse claim, please enter at least one of the following search criteria and then click Show Results.',
  },
  initial: {
    title: 'Welcome to the Burnett Index!',
    text: 'Someone will contact you within one business day to complete your registration and payment.',
    text2: `The Burnett Index is the premier valuation data source for sexual misconduct claims. It is an essential
tool in the valuation of sexual abuse claims for use in litigation, mediation, and other forms of dispute
resolution. The Burnett Index provides a supportable and data-driven foundation for resolution of sexual
abuse claims. When all stakeholders possess accurate and comprehensive sexual abuse claim valuation
metrics, they are able to achieve expedited and cost-effective claim resolution.`,
    text3: `Burnett Index is also an invaluable tool which is used to more accurately assess sexual misconduct
exposure in the risk-transfer enterprise. This powerful implement helps underwriters determine the
scope of sexual misconduct risk and informs claims handling professionals in cost-effective dispute
resolution.`,
    text4: `Burnett Index offers atorneys and alternative dispute practitioners a sound basis to quickly resolve tragic
sexual abuse cases in a manner that is fair, equitable and compassionate. Burnett Index provides critical
information to the research community for the study of the immense cost and burden of sexual
misconduct upon society. It provides a foundation for policymakers' efforts to mitigate that cost.`,
    text5: `You have made a great decision to use this tool to inform your work. We are confident you will find that
the Burnett Index will enhance your decision making regarding sexual misconduct risk and claims
handling.`,
  },
  cases: {
    list: {
      title: 'Claim / Case',
      create: 'Create New Claim/Case',
      active: {
        title: 'Active',
        caption: 'Active Claims / Cases',
        table: {
          claimant: 'Claimant',
          caseName: 'Case / File Name',
          description: 'Description',
        },
        menu: {
          search: 'Run Search',
          close: 'Close / Archive',
          submit: 'Close and Submit',
          details: 'Details',
          edit: 'Edit',
          delete: {
            caption: 'Delete',
            question: 'Are you sure you want to delete this case?',
          },
        },
      },
      closed: {
        title: 'Closed / Archived',
        caption: 'Closed / Archived Claims / Cases',
        table: {
          claimant: 'Claimant',
          caseName: 'Case / File Name',
          description: 'Description',
          isSubmitted: 'Submitted to Burnett Index?',
          status: 'Status',
        },
        menu: {
          search: 'Run Search',
          reopen: 'Reopen',
          delete: {
            caption: 'Delete',
            question: 'Are you sure you want to delete this case?',
          },
        },
      },
      empty: {
        title: 'Your Claims',
        description: 'Click "Create New Claim" to add a claim.',
      },
    },
    form: {
      title: 'Enter Claim / Case Information',
      breadcrumbs: {
        cases: 'Cases',
      },
      range: {
        from: 'From',
        to: 'To',
      },
      caseInformation: {
        title: 'Case/File Information',
        subtitle:
          'Does this entry involve a single claimant or a group of claimants?',
        claimantCount: '# in Group or class',
        country: {
          label: 'Country',
          placeholder: 'Select country',
        },
        state: {
          label: 'State/Province',
          placeholder: 'Select state/province ',
        },
        region: {
          label: 'Region',
          placeholder: 'Select region',
        },
        damagesConsiderations: {
          label: 'Damages Considerations',
          placeholder: 'Select',
        },
        claimantName: {
          label: 'Claimant Name',
          placeholder: 'Enters claimant name',
        },
        group: {
          label: 'Group or Class of Claimants',
          placeholder: 'Select',
        },
        name: {
          label: 'Case/File No',
          placeholder: 'Enter case/file number',
        },
      },
      claimantInformation: {
        title: 'Claimant Information',
        gender: {
          label: 'Claimant Gender',
          placeholder: 'Select',
        },
        birthDate: {
          label: 'Birthdate',
          placeholder: 'Select',
        },
        ageAtStartOfAbuse: {
          label: 'Age at start of Abuse',
          placeholder: 'Select',
        },
        ageAtEndOfAbuse: {
          label: 'Age at end of Abuse',
          placeholder: 'Select',
        },
        ageCategory: {
          label: 'Age Category at Time of Abuse',
          placeholder: 'Select',
        },
        startedAbuseAt: {
          label: 'Dates / Period of Abuse',
          placeholder: 'From',
        },
        endOfAbuseAt: {
          placeholder: 'To',
        },
      },
      perpetratorInformation: {
        title: 'Perpetrator Information',
        name: {
          label: 'Name of Perpetrator',
          placeholder: 'Enter name of perpetrator',
        },
        job: {
          label: 'Perpetrator`s Job Title/Position',
          placeholder: 'Select',
        },
        religionOrder: {
          label: 'Religious Order',
          placeholder: 'Select',
        },
        organization: {
          label: 'Organization/Church/Employer of Perpetrator',
          placeholder: 'Select',
        },
        organizationType: {
          label: 'Organization Type',
          placeholder: 'Select',
        },
        subsidiary: {
          label: 'Subsidiary',
          placeholder: 'Select',
        },
      },
      typeOfAbuse: {
        title: 'Type/Nature/Details of Abuse',
        subtitle: 'Type/Nature/Details of Abuse (all that apply)',
        details: {
          label: 'Are abuse details available?',
        },
        level1: {
          label: 'LEVEL 1: ',
        },
        level2: {
          label: 'LEVEL 2: ',
        },
        level3: {
          label: 'LEVEL 3: ',
        },
        level4: {
          label: 'LEVEL 4: ',
        },
        level5: {
          label: 'LEVEL 5: ',
        },
        numberOfIncidents: {
          label: 'Number of Incidents of Abuse',
          placeholder: 'Enter',
        },
        numberOfPerpetrators: {
          label: 'Number of Perpetrators',
          placeholder: 'Enter',
        },
        isOtherActive: {
          label: 'OTHER:',
        },
      },
      legalDetails: {
        title: 'Legal Issues/Details',
        lawsuitFiled: {
          label: 'Lawsuit Filed',
        },
        hasClaimantRetainedAnAttorney: {
          label: 'Has Claimant Retained an Attorney? ',
        },
        liabilityDefense: {
          label: 'Liability Defenses (choose all that apply)',
          placeholder: 'Select',
        },
        perpetrator: {
          label: 'Perpetrator',
        },
        exceptions: {
          label: 'Exceptions/Rebuttals to Liability Defenses',
          placeholder: 'Select',
        },
        damagesComment: {
          label: 'Damages Comments',
        },
        otherLifeStress: {
          label: 'Claimant`s Other Life Stresses',
        },
        description: {
          label: 'Other Claim Details',
        },
      },
      resolutionDetails: {
        label: 'Enter Resolution Details',
        title: 'Resolution Details',
        resolutionDate: {
          label: 'Resolution Date',
          placeholder: 'Select',
        },
        totalResolutionAmount: {
          label: 'Total Resolution Amount',
        },
        claimantsCount: {
          label: 'No. of Claimants',
        },
        amountPerClaimant: {
          label: 'Amount Per Claimant',
        },
        totalDefenseCosts: {
          label: 'Total Defense Costs',
        },
        typeOfResolution: {
          label: 'Type of Resolution',
          placeholder: 'Select',
        },
        isPublic: {
          label: 'Is This Resolution',
        },
        note: 'You have indicated this is a confidential resolution. The contents of this claim entry, including all claim details and the resolution value, will remain confidential and will not be disclosed. The claim information will only be used in the averaging and metrics of claim valuation in the Burnett Valuation Index.',
      },
      reset: 'Clear All',
      search: 'Search Using this Criteria',
      submit: 'Save',
      submitAndSearch: 'Save & Run Search',
    },
    details: {
      tabs: {
        [CasesDetailsTab.INFO]: 'Information',
        [CasesDetailsTab.NOTES]: 'Notes',
        [CasesDetailsTab.RESOLUTION]: 'Resolution Details',
        [CasesDetailsTab.SEARCHES]: 'Searches',
      },
      actions: {
        runSearch: 'Run Search',
        close: 'Close / Archive',
        submit: 'Close and Submit',
        reopen: 'Reopen',
        delete: {
          label: 'Delete',
          question: 'Are you sure you want to delete this case?',
        },
      },
      caseInformation: {
        title: 'Case/File Information',
        claimantName: 'Claimant Name',
        caseId: 'Case/File No',
        createdAt: 'Date Form Created',
        updatedAt: 'Date Last Updated',
        status: 'Status',
      },
      claimantInformation: {
        title: 'Claimant Information',
        gender: 'Claimant Gender',
        birthdate: 'Birthdate',
        ageAtStartOfAbuse: 'Age at Time of Abuse',
        abusePeriod: 'Date of Abuse/Abuse Period',
        ageCategory: 'Age Category at Time of Abuse',
      },
      perpetratorInformation: {
        title: 'Perpetrator Information',
        nameOfPerpetrator: 'Name of Perpetrator',
        perpetratorsJob: 'Perpetrator`s Job Title/Position',
        religiousOrder: 'Religious Order',
        organization: 'Organization/Church/Employer of Perpetrator',
        organizationType: 'Organization Type',
        subsidiary: 'Subsidiary',
      },
      typeOfAbuse: {
        title: 'Type/Nature/Details of Abuse',
        detailsAvailable: 'Are abuse details available?',
        type: 'Type/Nature/Details of Abuse (all that apply)',
        level1: 'LEVEL 1',
        level2: 'LEVEL 2',
        level3: 'LEVEL 3',
        level4: 'LEVEL 4',
        level5: 'LEVEL 5',
        other: 'Other',
        numberOfIncidents: 'Number of Incidents of Abuse',
        numberOfPerpetrators: 'Number of Perpetrators',
      },
      legalDetails: {
        title: 'Legal Issues/Details',
        lawsuitFiled: 'Lawsuit Filed',
        hasClaimantRetainedAnAttorney: 'Has Claimant Retained an Attorney?',
        liabilityDefense: 'Liability Defenses (choose all that apply)',
        exceptions: 'Exceptions/Rebuttals to Liability Defenses',
        perpetrator: 'Perpetrator',
      },
      resolutionDetails: {
        title: 'Resolution Details',
        date: 'Resolution Date',
        totalResolutionAmount: 'Total Resolution Amount',
        numberOfClaimants: 'No. of Claimants',
        amountPerClaimant: 'Amount Per Claimant',
        totalDefenseCosts: 'Total Defense Costs',
        typeOfResolution: 'Type of Resolution',
        privacyType: 'Privacy Type',
      },
      notes: {
        title: 'Notes',
        addButton: 'Add Note',
        form: {
          heading: 'Add Comment',
          title: {
            label: 'Title',
            placeholder: '',
          },
          comments: {
            label: 'Comments',
            placeholder: '',
          },
          buttons: {
            cancel: 'Cancel',
            submit: 'Save',
          },
        },
      },
    },
    common: {
      status: {
        [CaseStatus.APPROVED]: 'Accepted',
        [CaseStatus.CLOSED]: 'Closed',
        [CaseStatus.DRAFT]: 'Draft',
        [CaseStatus.PENDING]: 'Pending',
        [CaseStatus.DENIED]: 'Declined',
      } satisfies Record<CaseStatus, string>,
      yes: 'Yes',
      no: 'No',
    },
  },
  search: {
    results: {
      title: 'Search Results',
      pdfButton: 'Generate PDF Report',
      saveSearchButton: 'Save Search',
      empty: 'No data for construction',
      notificationResetButton: 'Reset',
      additionalFilters: {
        [AdditionalFilterParameter.AMOUNT_LOW]: 'Settlement Range',
        [AdditionalFilterParameter.AMOUNT_HIGH]: 'Settlement Range',
      },
    },
    resultsChart: {
      title: 'Range of Search Result Claim Values',
    },
  },
  abuseTypeLevels: {
    [AbuseDetailsKeys.DETAIL_AVAILABLE]: 'detail-available',
    [AbuseDetailsKeys.LEVEL_1]: 'Level 1',
    [AbuseDetailsKeys.LEVEL_2]: 'Level 2',
    [AbuseDetailsKeys.LEVEL_3]: 'Level 3',
    [AbuseDetailsKeys.LEVEL_4]: 'Level 4',
    [AbuseDetailsKeys.LEVEL_5]: 'Level 5',
    [CaseStatisticsLevel.LEVEL_1]: 'Level 1',
    [CaseStatisticsLevel.LEVEL_2]: 'Level 2',
    [CaseStatisticsLevel.LEVEL_3]: 'Level 3',
    [CaseStatisticsLevel.LEVEL_4]: 'Level 4',
    [CaseStatisticsLevel.LEVEL_5]: 'Level 5',
    [AbuseDetailsKeys.LEVEL_1_UNSPECIFIED]: 'level-1-unspecified',
    [AbuseDetailsKeys.LEVEL_2_UNSPECIFIED]: 'level-2-unspecified',
    [AbuseDetailsKeys.LEVEL_3_UNSPECIFIED]: 'level-3-unspecified',
    [AbuseDetailsKeys.LEVEL_4_UNSPECIFIED]: 'level-4-unspecified',
    [AbuseDetailsKeys.LEVEL_5_UNSPECIFIED]: 'level-5-unspecified',
    [AbuseDetailsKeys.OTHER]: 'Other',
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]: 'number-of-incidents-low',
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]: 'number-of-incidents-high',
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]: 'number-of-perpetrators-low',
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]:
      'number-of-perpetrators-high',
  },
  abuseTypeItems: {
    [AbuseDetailsLevel1VariantKeys.SUGGESTIVE_SEXUAL_TALK]:
      'Suggestive Sexual Talk',
    [AbuseDetailsLevel1VariantKeys.KISSING_ANG_HUGGING]:
      'Kissing/Kissing and Hugging',
    [AbuseDetailsLevel1VariantKeys.TOUCHING_NON_PRIVATE_PARTS]:
      'Touched Non-Private Parts',
    [AbuseDetailsLevel1VariantKeys.PROVIDING_VIEWING_PORNOGRAPHY]:
      'Providing/Viewing Pornography',
    [AbuseDetailsLevel1VariantKeys.EXPOSURE_OF_PERPETRATOR_TO_GENITALS]:
      'Exposure of Perpetrator’s Genitals',
    [AbuseDetailsLevel1VariantKeys.BUTTOCKS_FONDING]: 'Buttocks Fondling',
    [AbuseDetailsLevel2VariantKeys.WITNESS_PERPETRATOR_MASTURBATE]:
      'Witness Perpetrator Masturbate',
    [AbuseDetailsLevel2VariantKeys.PROVIDED_ALCOHOL_DRUGS]:
      'Provided Alcohol/Drugs ',
    [AbuseDetailsLevel2VariantKeys.FRENCH_KISSING]: '“French Kissing”',
    [AbuseDetailsLevel2VariantKeys.BREAST_FONDLING]: 'Breast Fondling ',
    [AbuseDetailsLevel2VariantKeys.PRESSING_GENITALS_AGAINST_CLAIMANT]:
      'Pressing Genitals Against Claimant',
    [AbuseDetailsLevel2VariantKeys.CLAIMANT_IS_UNDRESSED_NAKED]:
      'Claimant is Undressed/Naked',
    [AbuseDetailsLevel2VariantKeys.OVER_THE_CLOTHES_GENITAL_FONDLING]:
      'Over the Clothes Genital Fondling',
    [AbuseDetailsLevel3VariantKeys.MUTUAL_SELF_MASTURBATION]:
      'Mutual Self-Masturbation',
    [AbuseDetailsLevel3VariantKeys.MUTUAL_MASTURBATION_OF_EACH_OTHER]:
      'Mutual Masturbation of Each Other ',
    [AbuseDetailsLevel3VariantKeys.SEX_TOYS_PARAPHERNALIA]:
      'Sex Toys/Paraphernalia ',
    [AbuseDetailsLevel3VariantKeys.COERCED_MASTURBATION_OF_PERPETRATOR]:
      'Coerced Masturbation of Perpetrator',
    [AbuseDetailsLevel3VariantKeys.SKIN_TO_SKIN_GENITAL_FONDLING]:
      'Skin-to-Skin Genital Fondling ',
    [AbuseDetailsLevel4VariantKeys.ORAL_SEX_UPON_CLAIMANT]:
      'Oral Sex Upon Claimant',
    [AbuseDetailsLevel4VariantKeys.ATTEMPTED_SODOMY]: 'Attempted Sodomy',
    [AbuseDetailsLevel4VariantKeys.ANAL_DIGITAL_PENETRATION]:
      'Anal Digital Penetration ',
    [AbuseDetailsLevel4VariantKeys.WEAPON_INTIMIDATION]: 'Weapon Intimidation',
    [AbuseDetailsLevel4VariantKeys.VAGINAL_DIGITAL_PENETRATION]:
      'Vaginal Digital Penetration',
    [AbuseDetailsLevel4VariantKeys.ORAL_ANAL_STIMULATION_OF_CLAIMANT]:
      'Oral/Anal Stimulation of Claimant',
    [AbuseDetailsLevel4VariantKeys.COERCED_ORAL_SEX_UPON_PERPETRATOR]:
      'Coerced Oral Sex Upon Perpetrator',
    [AbuseDetailsLevel5VariantKeys.ANAL_SODOMY]: 'Anal Sodomy',
    [AbuseDetailsLevel5VariantKeys.RAPE]: 'Rape',
    [AbuseDetailsLevel5VariantKeys.VAGINAL_INTERCOURSE]: 'Vaginal Intercourse',
    [AbuseDetailsLevel5VariantKeys.SEX_TRAFFICKING]: 'Sex Trafficking',
    [AbuseDetailsLevel5VariantKeys.COERCED_ORAL_ANAL_STIMULATION_OF_PERPETRATOR]:
      'Coerced Oral/Anal Stimulation of Perpetrator',
    [AbuseDetailsLevel5VariantKeys.CREATION_OF_CHILD_PORNOGRAPHY_USING_CLAIMANT]:
      'Creation of Child Pornography Using Claimant',
    [AbuseDetailsLevel1VariantKeys.UNSPECIFIED]: 'Unspecified',
  },
  perpetrator: {
    [LegalDetailsPreparatorVariantsKey.ADMITTED_ABUSE]: 'Admitted Abuse',
    [LegalDetailsPreparatorVariantsKey.OTHERWISE_DEEMED_GUILTY]:
      'Otherwise Deemed Guilty',
    [LegalDetailsPreparatorVariantsKey.DENIED_ABUSE]: 'Denied Abuse',
    [LegalDetailsPreparatorVariantsKey.DECEASED_WHEN_ACCUSED]:
      'Deceased When Accused',
    [LegalDetailsPreparatorVariantsKey.CONVICTED]: 'Convicted',
  },
  gender: {
    [VictimInfoGenderVariantKeys.ALL]: 'All',
    [VictimInfoGenderVariantKeys.FEMALE]: 'Female',
    [VictimInfoGenderVariantKeys.MALE]: 'Male',
    [VictimInfoGenderVariantKeys.UNKNOWN]: 'Unknown',
  },
  ageCategory: {
    [VictimInfoAgeCategoryVariantKeys.ADULT]: 'Adult',
    [VictimInfoAgeCategoryVariantKeys.MINOR]: 'Minor',
    [VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT]: 'Minor to adult',
    [VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT]: 'Teen to adult',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT]: 'Prepubescent',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT]:
      'Prepubescent to adult',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN]:
      'Prepubescent to teen',
    [VictimInfoAgeCategoryVariantKeys.TEEN]: 'Teen',
  },
  ageCategoryDescription: {
    [VictimInfoAgeCategoryVariantKeys.ADULT]: '18 and Over',
    [VictimInfoAgeCategoryVariantKeys.MINOR]:
      'Under Age 18 but the age may be unknown as yet',
    [VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT]:
      'Period of abuse included unknown specific ages under age 18 but the period of abuse was known to span into ages 18 and over',
    [VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT]:
      'Period of abuse included teenage years (13 to 17) and spanning into ages 18 and over',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT]: 'Under Age 13',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN]:
      'Age Range during period of abuse spanning years when the victim was under age 13 to when victim was into the teenage years under age 18',
    [VictimInfoAgeCategoryVariantKeys.TEEN]: 'Age 13 to Under Age 18',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT]:
      'Age Range during period of abuse spanning years when the victim was under age 13 and spanning into ages 18 and over',
  },
  jobs: {
    [PerpetratorInfoJobVariantKeys.COACH]: 'Coach',
    [PerpetratorInfoJobVariantKeys.ELDER]: 'Elder',
    [PerpetratorInfoJobVariantKeys.FAMILY_MEMBER]: 'Family member',
    [PerpetratorInfoJobVariantKeys.MAINTENANCE]: 'Maintenance',
    [PerpetratorInfoJobVariantKeys.MINISTER]: 'Minister',
    [PerpetratorInfoJobVariantKeys.MONK]: 'Monk',
    [PerpetratorInfoJobVariantKeys.OTHER]: 'Other',
    [PerpetratorInfoJobVariantKeys.PRIEST]: 'Priest',
    [PerpetratorInfoJobVariantKeys.SISTER_NUN]: 'Sister nun',
    [PerpetratorInfoJobVariantKeys.STAFF]: 'Staff',
    [PerpetratorInfoJobVariantKeys.TEACHER]: 'Teacher',
  },
  religionOrders: {
    [PerpetratorInfoReligionOrder.BENEDICTINES]: 'Benedictines',
    [PerpetratorInfoReligionOrder.DOMINICANS]: 'Dominicans',
    [PerpetratorInfoReligionOrder.HOLY_CROSS]: 'Holy cross',
    [PerpetratorInfoReligionOrder.JESUITS]: 'Jesuits',
  },
  organizations: {
    [PerpetratorInfoOrganizationVariantKeys.BAPTIST_CHURCH]: 'Baptist church',
    [PerpetratorInfoOrganizationVariantKeys.BOY_SCOUTS_OF_AMERICA]:
      'Boy scouts of america',
    [PerpetratorInfoOrganizationVariantKeys.EPISCOPAL_CHURCH]:
      'Episcopal church',
    [PerpetratorInfoOrganizationVariantKeys.ROMAN_CATHOLIC_CHURCH]:
      'Roman catholic church',
  },
  subsidiaries: {
    [PerpetratorInfoSubsidiaryVariantKeys.ARCHDIOCESE_OF_NEW_YORK]:
      'Archdiocese of new york',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_ALBANY]:
      'Diocese of albany',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_BUFFALO]:
      'Diocese of buffalo',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_OGDENSBURG]:
      'Diocese of ogdensburg',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_ROCHESTER]:
      'Diocese of rochester',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_ROCKVILLE_CENTRE]:
      'Diocese of rockville centre',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_SYRACUSE]:
      'Diocese of syracuse',
  },
  liabilities: {
    [LegalDetailsLiabilityDefenseVariantsKey.STATUTE_OF_LIMITATIONS]:
      'Statute of limitations',
    [LegalDetailsLiabilityDefenseVariantsKey.STATUTE_OF_REPOSE]:
      'Statute of repose',
    [LegalDetailsLiabilityDefenseVariantsKey.CHARITABLE_IMMUNITY]:
      'Charitable immunity',
    [LegalDetailsLiabilityDefenseVariantsKey.CONSENSUAL]: 'Consensual',
    [LegalDetailsLiabilityDefenseVariantsKey.ADULT_RELATION_SHIP]:
      'Adult relation ship',
    [LegalDetailsLiabilityDefenseVariantsKey.FALSE_ACCUSATION]:
      'False accusation',
    [LegalDetailsLiabilityDefenseVariantsKey.FIRST_AMENDMENT]:
      'First amendment',
    [LegalDetailsLiabilityDefenseVariantsKey.UNFORESEEN_THIRD_PARTY_ATTACK]:
      'Unforeseen third party attack',
    [LegalDetailsLiabilityDefenseVariantsKey.NONE]: 'None',
  },
  exceptions: {
    [LegalDetailsExceptionsVariantsKey.NY_CHILD_VICTIM_ACT]:
      'NY child victim act',
    [LegalDetailsExceptionsVariantsKey.NJ_STATUTE_OF_LIMITATIONS_WINDOW]:
      'NJ statute of limitations window',
  },
  confidentiality: {
    public: 'Public',
    confidential: 'Confidential',
  },
  claimantTypeItems: {
    single: 'Single Claimant',
    group: 'Group or Class of Claimants',
  },
  abuseTypeLevelSwitch: {
    specified: 'Specified',
    unspecified: 'Unspecified',
  },
  agreementValues: {
    yes: 'Yes',
    no: 'No',
  },
  savedSearches: {
    title: 'Saved Searches',
    buttons: {
      repeatSearch: {
        question: 'Repeat this search?',
        caption: 'Repeat Search',
      },
      deleteSearch: {
        question: 'Are you sure you want to delete this search?',
        caption: 'Delete this Search',
      },
      deleteEntireSearch: {
        question: 'Are you sure you want to delete the entire search?',
        caption: 'Delete Entire Search',
      },
      saveSearch: {
        caption: 'Save Search',
      },
      applyFilter: {
        caption: 'Apply Filter',
      },
      startNewSearch: {
        caption: 'Start New Search',
      },
    },
    filterForm: {
      fields: {
        date: {
          label: 'Search Date',
          placeholder: 'All Dates',
        },
      },
    },
    saveSearchForm: {
      title: 'Save Search',
      fields: {
        name: {
          label: 'Search Name',
          placeholder: 'Enter search name',
        },
        userCase: {
          label: 'Assign to the Case/Claim',
          placeholder: 'Select Case/Claim',
        },
      },
    },
    table: {
      columns: {
        searchDates: 'Search Date/Time',
        userName: 'User Name',
      },
    },
    searchSavedMessage: {
      title: 'Search Saved',
      buttons: {
        backToSearch: 'Back to Search',
        openSavedSearches: 'Open Saved Searches',
      },
    },
  },
  savedSearchDetails: {
    title: 'Saved Searches',
    buttons: {
      searchAgain: {
        caption: 'Search Again',
      },
      deleteSearch: {
        question: 'Are you sure you want to delete this search?',
        caption: 'Delete Search',
      },
      printPdf: {
        caption: 'Generate PDF Report',
      },
    },
  },
  error: {
    form: {
      required: 'This field is required',
      short: '{{field}} must contain at least {{count}} character(s)',
      long: '{{field}} must contain at most {{count}} character(s)',
      email: 'Please enter a valid email address',
      password: {
        invalid: 'Invalid Password',
        mismatching: 'Passwords do not match',
      },
      phone: {
        invalid: 'Invalid Phone Number',
      },
    },
    api: {
      UNKNOWN_SERVER_ERROR: 'Unknown server error',
      INVALID_CREDENTIALS: 'Invalid Credentials',
      INCORRECT_DATA: 'Incorrect data',
      INCORRECT_FILTER: 'Incorrect filter value: {{field}}. Reset this field?',
      USER_NOT_FOUND: 'User not found',
      USER_ALREADY_EXISTS: 'Email already used',
      RESET_PASSWORD_TOKEN_NOT_FOUND:
        'Incorrect token. You might have opened the incorrect link or the link has expired.',
      CANNOT_FIND_CASE: 'Cannot find case',
      CANNOT_FIND_USER_CASE: 'Cannot find user case',
    },
  },
  dialog: {
    confirm: 'Yes',
    cancel: 'Cancel',
  },
  captions: {
    notAvailable: 'N/A',
  },
};
